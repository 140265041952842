<!-- Btra Botones de Mto -->

<template>
  <div class="baseBtraMto">
    <btra
        :btra="botonera"
        :modulo="modulo"
        :showDisabled="showDisabled"
        @onEvent="eventBtns">
          <template  v-for="elem in botonera"
            v-slot:[`${getNameSlot(elem.accion)}`]="{item,show,disabled}">
              <slot v-if="item.slot" :name="getNameSlot(item.accion)" :item="item" :show="show" :disabled="disabled"></slot>
          </template>
    </btra>
  </div>
</template>



<script>

  import plugs from "@/common/general_plugs";
  const btra = () => plugs.groute("baseBtra.vue", "base");

  export default {
    name:'baseBtraMto',
    components: { btra },
    props: {
      perm: { type: [Array, Object], default: null },
      modulo: { type: Object, default: null },
      estado: { type: [Number, String], default: null },
      showDisabled: { type: [Number, Boolean], default: 1 }
    },

    data() {
      return {
        botonera:[]
      }
    },


    created() {
      this.mountBtra();
    },


    methods: {
      // gestor de eventos
      eventBtns(evt)
      {
          console.log("*** event_btramto. Emit btramto: ", evt, " ***");
          this.$emit("onEvent", evt);
      },


      // devuelvo array de botones a mostrar
      mountBtra() {
        this.botonera= this.modulo? this.modulo.btnsAccion.slice() : [];

        // filtro array de botones por sus permisos
        if (this.estado== null) {
          this.botonera.map(elem=> this.$set(elem, 'show', 1));
          this.filterPermBtns();
          return;
        }

        // monto array botones según la acción recibida y filtro por sus permisos
        this.mountBtraEstado();
      },


      // según el estado recibido, modifico la botonera recibida. Después la filtro
      // por los permisos también recibidos. Si no recibo permisos, muestro el botón
      mountBtraEstado() {
        // monto botonera según el estado recibido
        switch (this.estado) {
          case "ver": // ver
            this.botonera.map((elem, idx)=> this.$set(elem, 'show', /^[0234]+$/.test(idx)? 1 : 0));
            break;

          case "nuevo":  // nuevo
          case "editar": // editar
            this.botonera.map((elem, idx)=> this.$set(elem, 'show', /^[056]+$/.test(idx)? 1 : 0));
            break;

          default:
            this.botonera.map((elem, idx)=> this.$set(elem, 'show', /^[0]+$/.test(idx)? 1 : 0));
        }

        // filtro por permisos particulares y de bd
        this.filterPermBtns();
      },


      // filtro botones por sus permisos
      filterPermBtns() {
        // si no he recibido permisos, devuelvo el array de botones sin modificarlo por permisos
        if (!this.perm) return this.botonera;

        // modifico el array de botones según los permisos recibidos
        this.botonera.forEach(elem => {
          if (elem.show && typeof this.perm[Number(elem.accion)]!== 'undefined') elem.show= Number(this.perm[Number(elem.accion)]);
        });
      },


      // devuelvo el nombre de la acción para identificar el slot
      getNameSlot(accion) {
        // (0:'ver', 1:'nuevo', 2:'editar', 3:'borrar', 4:'guardar', 5:'cancelar', 6:'salir')
        const mtoOptions= ['ver', 'nuevo', 'editar', 'borrar', 'guardar', 'cancelar', 'salir'];
        return (/^([0-9])*$/.test(accion))? mtoOptions[accion] : accion;
      },

    },


    watch: {
      estado() {
        this.mountBtra();
      }
    }

  };
</script>
