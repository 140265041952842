<!-- Finder expansible de Documentos -->

<!-- imagenes firma
     https://es.javascript.info/formdata -->

<template>
  <div class="commonDocsMX" v-if="loaded">
    <v-sheet class="contenedor" :elevation="0">

      <!-- Botones Mto -->
      <div class="pb-2 d-flex justify-left">
        <baseBtraMto class="conflex" style="width:230px;"
          :modulo="btMtoCfg"
          :estado="estado"
          @onEvent="execAccion">
        </baseBtraMto>
      </div>

      <!-- Controles del Mto -->
      <div style="display:flex">
        <uiText
          style="flex: 0 0 60%"
          v-model=" ct.name[2]"
          :label=" ct.name[1]"
          :disabled="noEdit">
        </uiText>

        <v-select
          style="flex: 0 0 40%"
          v-bind="$select"
          v-model=" ct.tpd[2]"
          :label=" ct.tpd[1]"
          :items="itemsTipoDocumentos"
          :disabled="noEdit"
          item-value="id"
          item-text="name">
        </v-select>
      </div>

      <v-textarea
        v-bind="$textarea"
        v-model=" ct.des[2]"
        :label=" ct.des[1]"
        rows="2"
        no-resize
        :disabled="noEdit">
      </v-textarea>

      <v-file-input
        v-model="files"
        accept="application/pdf"
        chips
        clearable
        dense
        rounded
        small-chips
        :disabled="estado != 'nuevo'">
      </v-file-input>

      <div style="display:flex">

        <uiDate
          v-model="ct.fhhr[2]"
          :label="ct.fhhr[1]"
          type="datetime-local"
          disabled>
        </uiDate>

        <uiText
          v-model=" ct.usu[2]"
          :label=" ct.usu[1]"
          disabled>
        </uiText>

        <uiText
          v-model=" ct.doc[2]"
          :label=" ct.doc[1]"
          disabled>
        </uiText>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixM],
    components: { baseBtraMto },
    props: {
      // Identifica la tabla asociada al documento
      // Descripcion de todas las tablas en la tabla documentos_tip
      tipoDocumento: { type: String, default: "0" },
    },

    data() {
      return {
        stIni: {
          api: "commonDocsMX",
          name:"commonDocsMX",
          titulo:"",
          recordAccess:"local",
          mView:'commonDocsMX', // Módulo manteniniento asociado
          pView:[],             // Permisos del mantenimiento asociado
          relation:'tip_id'     // campo de relación entre el id de la tabla maestra y el tip_id de documentos
        },

        // variable temporal para guardar imágenes que se suben al servidor
        files:null,

        // array de tipo de documentos
        itemsTipoDocumentos:[]
      }
    },


    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");

        // array de tipo de documentos posibles
        this.itemsTipoDocumentos= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_documento));

        // filtro en los tipos de documento posibles solo los que pueden corresponder a esa tabla (documentos_tip)=tipoDocumento
        // se reconoce porque hay un 1 en la posicion number(tipodocumento) del string
        this.itemsTipoDocumentos= this.itemsTipoDocumentos.filter(elem => elem.modulos.charAt(this.tipoDocumento== 1));
      },


      // actualizo apiArgs
      iniDataApi() {
        this.apiArgs.set= ["mto", 'guardar', {}, this.stIni.api];
        this.apiArgs.del= ["mto", 'borrar', { id: 0, tip:0, tip_id:0 }, this.stIni.api];
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },


      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.$set(this.ct.tip, 2, this.tipoDocumento);
      },


      // validación de datos antes de guardar
      validateParticular() {
        // compruebo si hemos seleccionado un documento
        if (!this.files && this.estado == "nuevo") {
          this.$root.$alert.open("Debe seleccionar un documento", "error");
          return false;
        }

        return true;
      },


      // (override) gancho MixM para devolver el array con los datos a guardar
      getRecordChangesToSave() {
        // devuelvo record par->valor con todos los campos del ct que NO tengan 'nosave'
        return this.ctrl2record(this.ct, this.record, false);
      },


      // (override) hago un override porque para guardar una imagen hay que
      // llamar la función stGuardarFormData en vez de stGuardar
      async guardarAPI(recordChanges) {
        let apiResult = await this.stGuardarFormData(recordChanges);

        // Pendiente: controlar error API
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error en grabación', 'error');
          return;
        }

        this.$root.$alert.open("Registro guardado correctamente!", "success", 1000);


        // actualizo record maestro, si corresponde, y mto
        this.guardarUpdate();
      },


      async stEliminar() {
        // apiArg: llamada API definida en el particular.
        // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
        let param = { apiArg: this.apiArgs.del, args: { id: this.ID, tip: this.tipoDocumento, tip_id:this.relationID } };
        console.log("stEliminar *************************:", param);
        return await this.$store.dispatch(this.storeName + "/eliminar", param);
      },


      // gancho después de guardar
      // refresco Finder
      /* guardarFin() {
        this.$store.commit(this.masterStore + '/disparoSet');
      }, */


      // gancho después de eliminar
      // refresco Finder
      /* eliminarFin() {
        this.$store.commit(this.masterStore + '/disparoSet');
      }, */

    },


    computed: {

      relationID() {
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;
        return this.recordAux.id;
      },

    }

};
</script>
